import React, { PropsWithChildren, MouseEvent, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapModal from 'react-bootstrap/Modal';

import { Icon } from '@common/components/icon';
import { Button } from '../button';
import Tooltip from '../tooltip';

const ModalBodyFullWidth = ({ children }: { children: any }) => <div className="modal-body--full-width">{children}</div>;

type DefaultWrapperProps = {
  children: React.ReactNode;
};
const DefaultWrapper = memo(({ children }: DefaultWrapperProps) => {
  return <div className="Modal__Wrapper">{children}</div>;
});

type ModalContentProps = PropsWithChildren<{
  title?: JSX.Element | JSX.Element[] | string;
  tooltip?: string;
  onHide?: () => void;
  onSaveAndClose?: () => void;
  onConfirm?: ((event: MouseEvent<HTMLButtonElement>) => void) | (() => void) | (() => Promise<void>);
  fullWidthContent?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  confirmButtonLoading?: boolean;
  confirmButtonType?: 'button' | 'submit';
  confirmButtonForm?: string;
  wrapperProps?: Record<string, any>;
  wrapper?: any;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideConfirm?: boolean;
  customFooter?: ReactNode;
  bodyClassName?: string;
  footerClassName?: string;
  footerChildren?: ReactNode;
  headerContent?: ReactNode;
}>;

const ModalContent = memo(({
  title,
  children,
  onHide,
  onConfirm,
  wrapper,
  wrapperProps,
  tooltip,
  fullWidthContent = false,
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled = false,
  confirmButtonLoading = false,
  confirmButtonType = 'button',
  hideHeader = false,
  confirmButtonForm,
  hideFooter = false,
  hideConfirm = false,
  customFooter,
  bodyClassName,
  footerClassName,
  footerChildren,
  headerContent,
}: ModalContentProps) => {

  const Wrapper = wrapper || DefaultWrapper;
  const BodyWrapper = fullWidthContent ? ModalBodyFullWidth : BootstrapModal.Body;
  const { t } = useTranslation();

  const renderFooter = () => {
    if (hideFooter) return null;

    const footerContent = customFooter || (
      <div className={`pull-right ${footerClassName || ''}`}>
        {onHide && (
          <Button onClick={onHide}>
            {cancelButtonText || t('common:modal_footer_button_cancel')}
          </Button>
        )}
        {footerChildren}
        {!hideConfirm && (
          <Button
            onClick={onConfirm}
            type="primary"
            disabled={confirmButtonDisabled}
            isLoading={confirmButtonLoading}
            buttonType={confirmButtonType}
            form={confirmButtonForm}
          >
            {confirmButtonText || t('core:confirm')}
          </Button>
        )}
      </div>
    );

    return (
      <BootstrapModal.Footer>{footerContent}</BootstrapModal.Footer>
    );
  };

  const titleContent = (
    <h2 className="Modal__title">
      {title}
    </h2>
  );

  return (
    <Wrapper {...wrapperProps}>
      {!hideHeader && (
        <BootstrapModal.Header>
          <div className="Modal__MainHeader">
            {tooltip ? (
              <Tooltip title={tooltip} placement="bottom" fullTitle>
                <div className="Modal__Tooltip">
                  {titleContent}
                  <Icon type="info" size="small" />
                </div>
              </Tooltip>
            ) : titleContent}
            {onHide && (
              <Icon
                className="Modal__HeaderClose"
                type="close"
                onClick={onHide}
              />
            )}
          </div>
          {headerContent}
        </BootstrapModal.Header>
      )}
      <BodyWrapper className={bodyClassName}>
        {children}
      </BodyWrapper>
      {renderFooter()}
    </Wrapper>
  );
});

export default ModalContent;
